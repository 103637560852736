
<!-- -------------------------------------------------------------------------------- -->

<script context="module"> 
	import { slide } from "svelte/transition"
	import { onMount, getContext } from 'svelte'
	import LoopIcon from "./LoopIcon.svelte"
	import {
		lang,
		thumbPath,
		focusedLoop, currentLoop, currentLoopIsPlaying,
		currentHotspot,  krpano, 
		loopPlayerOpen,
	} from "../Store/store.js"

	export function deactivateLoop(){
		console.log("deactivateLoop")
		// deactivateMe()
		// activeItem.set(id);
		// this.active = false;
	}
</script> 

<!-- -------------------------------------------------------------------------------- -->

<script>
	export let item;
	export let context;

	let app = getContext( 'app' );

	const { registerItem, activeItem } = getContext(context);
	const id = registerItem();

	$: active = $activeItem == id;
	// $: console.log("active ID:",id, "-",  active);

	export function deactivateMe(){
		console.log("deactivateMe()")
		activeItem.set(null);
	}

	function activateMe(loop) {
		if (app.trace) console.log("activateMe(", loop.id_new, ")", loop.place, $focusedLoop, $currentLoop)
		// console.log(me)
		

		if ($focusedLoop == $currentLoop){
			// activeItem.set(null);
			app.setLoopActive(null)
		} else {
			// activeItem.set($activeItem != id ? id : null);
			app.setLoopActive(loop, "noScroll");
		}
	};
	function fix(c) {
		// c > 77 ? c - 1 : c
		// console.log( "|–– fix(",c,")" )
		let s = 0
		// console.log( "|–– fix(",s,")" )
		if (c > 77 ) s++
		// console.log( "|–– fix(",s,")" )
		if (c > 221) s++
		// console.log( "|–– fix(",s,")" )
		if (c > 310) s=s-1
		// console.log( "|–– fix(",s,")" )
		c = c-s
		// console.log( "|–– fix(",c,")" )
		return c
	}
	export function test() {
		// console.log( "huhu", i.id_new )
		if (app.trace) console.log( "huhu" )
	}
	onMount(() => {
		// console.log("loop", id, item.nr, item.id_new)
		// console.log("huhu", item.id_new, " ", id, " ", `http://memoryloops.org/${$thumbPath}audiospot${fix(id)}.gif` );
		// console.log( `<a href="http://memoryloops.org/${$thumbPath}audiospot${fix(id)}.gif"><img src="http://memoryloops.org/${$thumbPath}audiospot${fix(id)}.gif" /></a>audiospot${fix(id)}.gif<br/>` );
  });

</script>

<!-- -------------------------------------------------------------------------------- -->


<div class="wrapper" id='ml{item.id_new}'
	on:click|stopPropagation='{()=>activateMe(item)}'
	on:mouseenter|self|stopPropagation='{()=>app.setLoopFocus(item)}'
>

	<div class="top">
		<div class="mr-0 text-center">
			<LoopIcon currentID="{item.id_new}" type="list"/> 
		</div>

		<div class="helvetica w-5 mr-2 text-gray666">
			{item.file}
		</div>

		<div class="flex-grow mr-2">
			<button class="listPlace">{item.place}</button>
		</div>
		
		<div class="helvetica flex-grow text-right text-xs text-gray666 mr-1">
				{item.duration}
		</div>
		
		<div class="download-button ml-1 mr-2 " >
			 <div class="download-icon" on:click="{()=>app.openDownloadWindow(id)}"></div>	
		</div>
	</div>


	{#if ($currentLoop === item.id_new || active) }
		<div class="bottom mb-2" transition:slide>
			<div class="details flex mt-2 mb-1 text-gray777 ">
				<div class="details-location flex-1 text-left overflow-hidden whitespace-normal">
					<!-- <p>{item.place}<br>{item.description}<br>{fix(id)}<br>{id}<br>{id} {item.nr} {active}</p> -->
					<p>{item.place}<br>{item.description}</p>
				</div>
				<div class="mr-3 text-right h-12">
					<img on:load='{()=>test(item)}' class="thumb" src="{$thumbPath}audiospot{item.nr}.gif" alt="Preview Image">
				</div>
			</div>
		</div>
	{/if}
					<!-- <img on:load='{()=>test(item)}' class="thumb" src="{$thumbPath}audiospot{fix(id)}.gif" alt="Preview Image"> -->

</div>

<!-- -------------------------------------------------------------------------------- -->

<style>
	.bg { 
		width: 300px !important;
		z-index: 999999999 !important;
		height: 20px !important;
		position: relative !important;
		margin-top: -24px !important;	
	}
	.listPlace {
		outline: none;
		font-family: "GraphikBold", sans-serif !important;
		letter-spacing: 0.3px !important;
	}
	.details-location {
		font-family: "GraphikMedium" !important;
		margin-left: 60px !important;
		margin-right: 5px !important;
	}
	.download-button {
		cursor: pointer;
		overflow: hidden;
		background: url('./assets/images/icon-download.svg') no-repeat;
		background-size: auto 11px;
	}
	.download-icon {
		width: 15px;
		height: 13px;
	}
	.wrapper {
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		background: url('./assets/images/looplist-details-bg.png') no-repeat;
		background-size: 100% 100%;
	}
	.top {
		font-size: .75em !important;
		display: flex;
		flex-direction: row;	
		justify-content: space-between;
		align-items: center;
		padding: 0.0rem;
		height: 26px;
		overflow: visible;
	}
	.bottom {
		display: flex;
		flex-direction: column;
		font-size: .75em !important;
	}
	.thumb {
		border: solid 1px #282828;
	}


</style>

<!-- ---------------------------------------------------------------------------------- -->
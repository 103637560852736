<!-- ---------------------------------------------------- -->
<script context="module">
  export const ThePlayer = {};
  const elements = new Set();

  import {
    lang,
    currentLoop,
    currentLoopGroupArray,
    currentLoopGroupRef,
    currentLoopIsPlaying,
  } from "../Store/store.js";

  export function stopAll() {
    elements.forEach((element) => {
      // console.log("|–– stopAll()", element.audioPlayPromise)
      if (element.audioPlayPromise !== undefined) {
        element.pause();
      } else {
      }
    });
  }
  export function stopOthers(me) {
    console.log("|–– stopOthers(", me, ")");
    elements.forEach((element) => {
      if (element !== me) element.pause();
    });
  }
  export function pause() {
    console.log("|–– pause() ", audioPlayPromise);
    if (audio !== null) {
      audio.pause();
    }
  }
  function formatSecondsAsTime(secs) {
    // console.log("-:", secs )
    var sec = new Number();
    var min = new Number();
    sec = Math.floor(secs);
    min = Math.floor(sec / 60);
    // min = min >= 10 ? min : '0' + min;
    sec = Math.floor(sec % 60);
    sec = sec >= 10 ? sec : "0" + sec;
    return min + ":" + sec;
  }
</script>

<!-- ---------------------------------------------------- -->
<script>
  import { onMount } from "svelte";
  import { onDestroy } from "svelte";
  import { getContext } from "svelte";
  // import { writable } from "svelte/store";

  // import {
  //   currentLoop,
  // 	currentLoopGroupArray,
  // 	currentLoopGroupRef,
  //   currentLoopIsPlaying,
  // } from "../Store/store.js"

  let app = getContext("app");

  export let src;
  export let title;

  let audio;
  let audioPlayPromise = false;
  let paused = true;
  let currentTime = "0:00";
  let duration = "0:00";
  let progressValue = 0.0;

  //-----------------------------

  $: if ($currentLoopIsPlaying) {
    // console.log("|–– $currentLoopIsPlaying = true")
    play();
  } else {
    // console.log("|–– $currentLoopIsPlaying = false")
    stopAll();
  }

  $: if ($currentLoopGroupRef) {
    // console.log("|–– $currentLoopGroupRef = true")
    app.getHotspot("player").registercontentsize(225, 200);
  } else {
    // console.log("|–– $currentLoopGroupRef = false")
    app.getHotspot("player").registercontentsize(225, 80);
  }

  // --------------------------

  onMount(() => {
    // console.log("hello LoopPlayer");
    elements.add(audio);
  });

  onDestroy(() => {
    // console.log("|–– onDestroy()")
    stopAll();
    elements.delete(audio);
  });

  //---------------------------

  // export function stopOthers(me) {
  // 	console.log("|–– stopOthers(",me,")")
  // 	elements.forEach(element => {
  // 		if (element !== audio) element.pause();
  // 	});
  // };

  export function tooglePlay() {
    // console.log("|–– tooglePlay2() ")
    $currentLoopIsPlaying = !$currentLoopIsPlaying;
  }

  // export function tooglePlay2() {
  // 	console.log("|–– tooglePlay() ")
  // 	if (paused) {
  // 		play()
  // 	} else {
  // 		pause()
  // 	}
  // };

  export function play() {
    // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
    // console.log("|–– play() ", audio)

    if (audio !== undefined) {
      audio.audioPlayPromise = audio.play();
      // audio.play()
      //audio.addEventListener('timeupdate', updateProgress, false );
      //audio.addEventListener('ended', progressFinished, false );

      if (audio.audioPlayPromise !== undefined) {
        audio.audioPlayPromise
          .then((_) => {
            audio.addEventListener("timeupdate", updateProgress, false);
            audio.addEventListener("ended", progressFinished, false);
            $currentLoopIsPlaying = true;
          })
          .catch((error) => {
            // Auto-play was prevented
            // Show paused UI.
            // console.log("|-- audioPlayPromise rejected")
            $currentLoopIsPlaying = false;
          });
      }
    } else {
      console.log(
        "|++++++++++++++++++ Audio is undefined +++++++++++++++++++++++"
      );
    }

    // $currentLoopIsPlaying = true
  }

  // export function pause() {
  // 	console.log("|–– pause() ")
  // 	if (audio !== null){
  // 		audio.pause()
  // 		// $currentLoopIsPlaying = false
  // 	}
  // };

  function setProgress(e) {
    // console.log("|–– setProgress() ", progressValue, x, y, p, audio.currentTime)
    let w = e.currentTarget.offsetWidth;
    let x = e.layerX - e.currentTarget.offsetLeft;
    let y = e.layerY - e.currentTarget.offsetTop;
    let p = x / w;
    audio.currentTime = audio.duration * p;
    $currentLoopIsPlaying = true;
  }

  function progressFinished() {
    $currentLoopIsPlaying = false;
  }

  function updateProgress() {
    // console.log("|–– updateProgress() ", progressValue)
    if (audio !== null) {
      if (audio.currentTime > 0.0) {
        currentTime = formatSecondsAsTime(audio.currentTime);
        duration = formatSecondsAsTime(audio.duration);
        progressValue = audio.currentTime / audio.duration;
      }
    }
  }

  const Assoziierte = {
    de: "Verknüpfte Tonspuren:",
    en: "Linked audio tracks:",
  };
</script>

<!-- ---------------------------------------------------- -->

<div id="Details" class="flex-vert">
  <button class="progress-btn w-full" on:click={(e) => setProgress(e)}>
    <progress value={progressValue} max="1" />
  </button>
  <!-- 
		<button class="mr-2 self-center hover:opacity-75" class:playing={$currentLoopIsPlaying} on:click={$currentLoopIsPlaying = !$currentLoopIsPlaying}>
-->
  <div class="flex h-4">
    <button
      class="mr-2 self-center hover:opacity-75"
      class:playing={$currentLoopIsPlaying}
      on:click={() => tooglePlay()}
    >
      {#if $currentLoopIsPlaying}
        <img class="w-5" src="./assets/images/cntrl-pause.svg" alt="Pause" />
      {:else}
        <img class="w-5" src="./assets/images/cntrl-play-bg.svg" alt="Play" />
      {/if}
    </button>
    <img
      class="self-center w-3 mr-1 text-right"
      src="./assets/images/clock.svg"
      alt="Clock"
    />
    <div class="self-center flex-grow time mr-2 text-left">
      {currentTime} / {duration}
    </div>
    <button
      class="download-button self-center ml-1 mr-0 hover:opacity-75"
      on:click={() => app.openDownloadWindow()}
    />
  </div>

  {#if $currentLoopGroupRef}
    <div class="groupListContainer">
      <div class="line" />
      <div class="associates">
        {Assoziierte[$lang]}
      </div>
      <div class="content text-xs">
        {#each $currentLoopGroupArray as groupID}
          {#if groupID !== $currentLoop}
            <div
              class="item flex h-4 text-left hover:bg-gray444"
              on:click={() => app.setLoopActive(groupID)}
              on:mouseenter|self|stopPropagation={() =>
                app.setLoopFocus(groupID)}
              on:mouseleave|self|stopPropagation={() => app.setLoopFocus()}
            >
              <div class="item-id self-center ml-1 mr-0 text-left text-gray777">
                {app.getCurrentLoopGroupIDFile(groupID)}
              </div>
              <div class="address flex-grow self-center ml-2 mr-1 text-left">
                {app.getCurrentLoopGroupIDAddress(groupID)}
              </div>
              <div
                class="item-duration self-center flex-1 mr-0 text-right opacity-50 "
              >
                {app.getCurrentLoopGroupIDDuration(groupID)}
              </div>
              <img
                class="self-center w-3 ml-1 mr-1"
                src="./assets/images/clock.svg"
                alt=""
              />
            </div>
          {/if}
        {/each}
      </div>
    </div>
  {/if}
</div>

<!-- ---------------------------------------------------- -->

<audio bind:this={audio} {src} />
<!-- 
<audio  bind:this={audio}  bind:paused  on:play={()=>{stopOthers(audio)}} {src} ></audio>
-->

<!-- ---------------------------------------------------- -->
<style>
  .groupListContainer {
    z-index: 1000;
    position: absolute;
    top: 70px;
    color: #ccc;
    width: 170px;
    max-height: 100px;
    padding: 0px 0px;
    background-color: black;
    border: 0px solid rgb(38, 38, 38);
    border-radius: 0px;
  }
  .time {
    font-family: "GraphikMedium";
    font-size: 0.7rem;
    letter-spacing: 0.03rem;
    padding-top: 1px;
  }
  .address {
    font-family: "GraphikMedium";
    letter-spacing: 0.03rem;
    opacity: 0.9;
    max-width: 52%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .item {
    font-size: 0.65rem;
    border: 0px solid rgb(38, 38, 38);
    border-radius: 6px;
  }
  .item-id {
    font-family: "GraphikMedium" !important;
    letter-spacing: 0.03rem !important;
  }
  .item-duration {
    font-family: "GraphikMedium" !important;
    letter-spacing: 0.03rem !important;
  }
  .content {
    cursor: pointer;
    color: white;
    height: 73px;
    width: 170px;
    margin: 4px 0px 0px -2px;
    border: 0px solid rgb(38, 38, 38);
    border-radius: 0px;
    overflow: auto;
  }
  .download-button {
    width: 14px;
    height: 16px;
    cursor: pointer;
    /* overflow: hidden; */
    background: url("./assets/images/icon-download.svg") no-repeat;
  }
  /* .download-icon { */
  /* width: 15px; */
  /* height: 13px; */
  /* } */
  .playing {
    color: white;
  }

  .flex-vert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 174px;
  }
  .associates {
    font-family: "GraphikMedium";
    font-size: 0.75rem;
    margin-bottom: 6px;
    align-items: top;
    justify-content: left;
  }
  .line {
    margin: 0rem 0 0.7rem 0;
    flex-basis: 2px;
    align-items: center;
    justify-content: center;
    background-color: #222;
    border-radius: 1px;
  }

  progress {
    width: 100%;
    height: 3px;
    color: #00c3ff;
    /* background-color: #444; */
    vertical-align: middle;
    margin-bottom: 4px;
    border-radius: 1px;
  }
  /* Background Colors  */
  progress,                          /* Firefox  */
	progress[role][aria-valuenow] {
    /* Polyfill */
    background: #444 !important; /* !important is needed by the polyfill */
  }
  /* Chrome */
  progress::-webkit-progress-bar {
    background: #444;
  }
  /* Foreground Colors   */
  /* IE10 */
  progress {
    color: #00c3ff;
  }
  /* Firefox */
  progress::-moz-progress-bar {
    background: #00c3ff;
  }
  /* Chrome */
  progress::-webkit-progress-value {
    background: #00c3ff;
  }
  /* Polyfill */
  progress[aria-valuenow]:before {
    background: #00c3ff;
  }
  .progress-btn {
    margin-top: -7px;
  }
</style>

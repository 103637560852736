<!-- --------------------------------------------------------- -->
<svelte:options accessors />

<!-- --------------------------------------------------------- -->
<script>
  import App from "./App.svelte";
  // ---------- Data ------------
  import {
    loopFileType,
    basePath,
    mapPath,
    audioPath,
    thumbPath,
    downloadPath,
    focusedLoop,
    storedLoops,
    storedGroups,
    storedGroups_de,
    storedGroups_en,
    memoryloops_de,
    lookupTable,
    lang,
    sortBy,
    loopListOpen,
    loopPlayerOpen,
    // Player,
    // preview2,
    currentLoopIsPlaying,
    currentLoopAddress,
    currentLoopGroupArray,
    currentLoopGroupRef,
    currentLoopGroupID,
    currentLoopURL,
    currentLoop,
    currentHotspot,
    focusedHotspot,
    krpano,
  } from "./Store/store.js";

  // ---------- Basics ------------
  import { Machine, interpret, assign } from "xstate";
  import { writable } from "svelte/store";
  import { onMount, setContext, getContext } from "svelte";
  import { fade, fly } from "svelte/transition";
  import {
    quadIn,
    quadOut,
    quadInOut,
    quintInOut,
    circInOut,
  } from "svelte/easing";
  import Router, { link } from "./Components/Router.svelte";
  import active from "svelte-spa-router/active";
  import { location, push, pop, replace } from "svelte-spa-router";
  import routes from "./routes.js";
  import * as animateScroll from "svelte-scrollto";

  // ---------- Map Components ------------
  import Krpano from "./Krpano/Krpano.svelte";

  // ---------- UI Components ------------
  import LoopIcon from "./Components/LoopIcon.svelte";
  import LoopPlayer, { stopAll } from "./Components/LoopPlayer.svelte";
  import LoadingSpinner from "./Components/LoadingSpinner.svelte";
  import LoopListPanel from "./Components/LoopListPanel.svelte";
  import { deactivateLoop } from "./Components/LoopListItem.svelte";
  // import {activeItem} from './Components/LoopList.svelte'
  // import LoopPlayerPanel from './Components/LoopPlayerPanel.svelte'

  // ---------- State Machine -----------
  export let trace = true;
  export let isDevelopment = false;
  export let currentfps = 0;
  export let progress = 0;

  export let isOnline = false;

  export let isPhone = window.matchMedia(
    "only screen and (max-width: 420px)"
  ).matches;
  export let isMobile = window.matchMedia(
    "only screen and (max-width: 900px)"
  ).matches;
  // export let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;

  export let showGrimme = false;
  export let showMenuBar = false;
  export let showPartner = false;
  export let showSplash = false;
  export let showRoutes = false;

  export let showNavi = true;
  export let showMobileMenu = false;
  export let showMenu = false;
  export let showClaim = false;
  export let showHeader = true;
  export let showMap = false;
  export let showLoader = false;
  export let showLoopList = false;
  export let showLoopPlayer = false;
  export let showDebugPanel = false;

  export let _hidden = false;
  let preview_timeout = null;
  let hs_preview;
  let focusedL = "";

  export const centerPoint = { ath: -0.0276, atv: 0.16 };
  export const fovLevel = [1.0, 0.4, 0.203, 0.13, 0.05];
  // export let currFov
  export let prew = {};

  export const exploreStates = {
    // id: 'explore',
    initial: "map",
    entry: "showExploreState",
    exit: "hideExploreState",
    on: {
      TOGGLE: "#app.idle",
    },
    states: {
      map: {
        type: "final",
        entry: "showExploreMapState",
        exit: "hideExploreMapState",
        on: {
          // 'TOGGLE.EXPLORE': 'info'
          "TOGGLE.EXPLORE": "info",
          "EXPLORE.INFO": "info",
        },
      },
      info: {
        entry: "showExploreInfoState",
        exit: "hideExploreInfoState",
        on: {
          // 'TOGGLE.EXPLORE': 'map'
          "TOGGLE.EXPLORE": "map",
          "EXPLORE.MAP": "map",
        },
      },
    },
  };
  export const appStates = {
    id: "app",
    initial: "load",
    context: {
      isFirstTime: true,
    },
    states: {
      network: {
        entry: "notifyNetworkState",
        on: {
          ONLINE: {
            target: "load",
            actions: "notifyNetworkState",
          },
          OFFLINE: {
            target: "network",
            actions: "notifyNetworkState",
          },
        },
      },
      load: {
        entry: "showLoadState",
        exit: "hideLoadState",
        on: {
          LOADED: {
            target: "idle",
            actions: "notifyLoaded",
          },
        },
        // after: {
        // 3000: 'idle'
        // }
      },
      idle: {
        entry: "showIdleState",
        exit: "hideIdleState",
        on: {
          TOGGLE: "explore.map",
          EXPLORE: "explore.map",
        },
        // after: {
        // 3000: 'explore'
        // },
      },
      explore: {
        ...exploreStates,
      },
    },
  };
  export const appActions = {
    actions: {
      // LOAD ----------------------------------------

      showLoadState: (context, event) => {
        showLoader = true;
        showGrimme = false;
        showRoutes = false; //test
        showMap = true;
      },
      hideLoadState: (context, event) => {
        showLoader = false;
        setLoopFocus(null);
      },
      notifyLoaded: (context, event) => {
        if (trace) console.info("|-- loading finished ...", context, event);
      },

      // IDLE ----------------------------------------

      showIdleState: (context, event) => {
        showRoutes = false;
        showGrimme = showPartner = true;
        showMobileMenu = false;
        showMenuBar = false;
        showHeader = true;
        showClaim = false;
        showMap = true;
        showSplash = true;
        $krpano.call("tween(layer[splash].alpha, 1.0, 0.5);");
        $krpano.call(
          `lookto( ${centerPoint.ath}, ${centerPoint.atv}, ${fovLevel[2]}, smooth(2,3,8));`
        );
        setLoopActive(null);
        setLoopFocus(null);
      },
      hideIdleState: (context, event) => {
        showGrimme = showPartner = false;
        showMenuBar = true;
        showSplash = false;
        $krpano.call("tween(layer[splash].alpha, 0.0, 0.99);");
      },

      // EXPLORE ----------------------------------------

      showExploreState: (context, event) => {
        showClaim = true;
        $loopPlayerOpen = $loopListOpen = true;
      },
      hideExploreState: (context, event) => {
        $loopPlayerOpen = $loopListOpen = false;
      },

      // EXPLORE.MAP ----------------------------------------

      showExploreMapState: (context, event) => {
        showClaim = true;
        showRoutes = false; //test
        showLoopPlayer = showLoopList = true;
        $loopPlayerOpen = false;
        $loopListOpen = true;
      },
      hideExploreMapState: (context, event) => {
        $loopPlayerOpen = $loopListOpen = false;
      },

      // EXPLORE.INFO ----------------------------------------

      showExploreInfoState: (context, event) => {
        // showMap = false
        showClaim = true;
        showRoutes = true;
        showLoopPlayer = showLoopList = false;
        $loopPlayerOpen = $loopListOpen = false;
        setLoopActive(null);
        setLoopFocus(null);
      },
      hideExploreInfoState: (context, event) => {
        // showMap = true
        $loopPlayerOpen = $loopListOpen = false;
      },

      notifyNetworkState: (context, event) => {
        console.log("NETWORK STATE OFFLINE", event, context);
      },
    },
  };
  function createStateStore(_states, _actions) {
    let stateMsgElm,
      stateMsgText = _states.initialState;
    const machine = Machine(_states, _actions);
    const service = interpret(machine);
    service.start();
    service.onTransition((state) => {
      stateMsgElm = document.getElementById("state");
      stateMsgText = state.toStrings().slice(-1).toString().toUpperCase();
      if (stateMsgElm !== null) {
        stateMsgElm.innerHTML = stateMsgText;
        // if (trace) console.error('|-- Current State:', stateMsgText, isOnline )
      }
      // if (trace) console.error('|-- State changed:', state.changed )

      // if (state.matches('explore'))
      //   if (trace) console.error('|-- State matches: "EXPLORE"' )
      // else if (state.matches('load'))
      //   if (trace) console.error('|-- State matches: "LOAD"' )
      // else if (state.matches('idle'))
      //   if (trace) console.error('|-- State matches: "IDLE"' )
      return () => states.stop();
    });
    return {
      set: (e) => service.send(e),
      currentEvent: () => service.state.event.type,
      current: () => stateMsgText,
      state: () => service.state,
    };
  }
  export const states = createStateStore(appStates, appActions);

  setContext("app", {
    setLoopFocus: setLoopFocus,
    setLoopActive: setLoopActive,
    openDownloadWindow: openDownloadWindow,
    deleteHotspots: deleteHotspots,
    createHotspots: createHotspots,
    getCurrentLoopGroupIDFile: getCurrentLoopGroupIDFile,
    getCurrentLoopGroupIDAddress: getCurrentLoopGroupIDAddress,
    getCurrentLoopGroupIDDuration: getCurrentLoopGroupIDDuration,
    toggleLoopList: toggleLoopList,
    getfocusedLoop: getfocusedLoop,
    fovLevel: fovLevel,
    setPreview: setPreview,
    trace: trace,
    scrollToItem: scrollToItem,
    getHotspot: getHotspot,
  });

  export let animIn = { duration: 300, y: 0, opacity: 0.0, easing: quadOut };
  export let animOut = { duration: 300, y: 0, opacity: 0.0, easing: quadIn };

  export function getfocusedLoop() {
    return $focusedLoop;
  }

  export function setCurrentLoopIsPlaying() {
    $currentLoopIsPlaying = true;
    if (trace) console.log("|-- setCurrentLoopIsPlaying!");
  }
  export function toggleCurrentLoopIsPlaying() {
    $currentLoopIsPlaying = !$currentLoopIsPlaying;
    if (trace) console.log("|-- currentLoopIsPlaying toggled!");
  }
  export function setCurrentLoopIsPaused() {
    $currentLoopIsPlaying = false;
    if (trace) console.log("|-- setCurrentLoopIsPaused!");
  }

  function toggleMobileMenu() {
    showMobileMenu = !showMobileMenu;
  }
  function toggleGrimme() {
    showGrimme = !showGrimme;
  }
  function togglePartner() {
    showPartner = !showPartner;
  }
  function toggleMenu() {
    showMenu = !showMenu;
  }
  function toggleLanguage() {
    $lang = $lang === "en" ? "de" : "en";
    setLoopFocus(null);
    setLoopActive(null);
    deleteHotspots();
    createHotspots(20);
    hotswapLang();
  }
  function toggleLoopList() {
    $loopListOpen = !$loopListOpen;
    if (trace) console.log("toggleMenu");
  }
  function toggleLoopPlayer() {
    $loopPlayerOpen = !$loopPlayerOpen;
    if (trace) console.log("toggleLoopPlayer");
  }

  function hotswapLang() {
    if (trace) console.log("|––– HotSwapLang()", $lang, $location);
    if ($lang === "en") {
      switch ($location) {
        case "/ueber":
          push("/about");
          break;
        case "/hoerspiel":
          push("/radioplay");
          break;
        case "/hilfe":
          push("/help");
          break;
        case "/impressum":
          push("/imprint");
          break;
        case "/datenschutz":
          push("/privacy");
          break;
      }
    } else {
      switch ($location) {
        case "/about":
          push("/ueber");
          break;
        case "/radioplay":
          push("/hoerspiel");
          break;
        case "/help":
          push("/hilfe");
          break;
        case "/imprint":
          push("/impressum");
          break;
        case "/privacy":
          push("/datenschutz");
          break;
      }
    }
  }

  $: if ($krpano) {
    if ($loopListOpen)
      // $krpano.call( "tween(plugin[skin_map].alpha|plugin[skin_map].y, 1.0|plugin[skin_map].y, 0.5, default );" )
      $krpano.call("tween(plugin[skin_map].alpha, 1.0, 0.4, default );");
    // $krpano.call( "tween(plugin[skin_map].alpha|plugin[skin_map].y, 0.0|plugin[skin_map].y, 0.5, default );" )
    else $krpano.call("tween(plugin[skin_map].alpha, 0.0, 0.2, default );");
  }

  function closePlayer() {
    if (trace) console.log("closePlayer()");
    $krpano.call(
      "tween(hotspot[player].alpha, 0.0, 0.3, default, setLoopActive(null) );"
    );
  }

  /* ––––––––––––––––––––––––––––––––––––––––––––––– */
  /* ––––––––– LOOP ACTIVATION / FOCUSING –––––––––– */
  /* ––––––––––––––––––––––––––––––––––––––––––––––– */

  export function setLoopActive(loop, option) {
    if (trace) console.log("|-- setLoopActive(", loop, ") ", option);
    // stopAll()
    // deactivateLoop()
    // deactivateMe()

    if (loop) {
      if (trace) console.log("|-- setLoopActive(", loop, ") ");
      // console.log("|-- setLoopActive(",loop,") ", option)

      $loopPlayerOpen = true;
      $currentLoopIsPlaying = false;

      $currentLoop = $focusedLoop;
      $currentHotspot = $focusedHotspot;
      $currentLoopGroupID = $currentHotspot.group_id;
      $currentLoopGroupRef = $currentHotspot.group_ref;
      if ($lang === "de")
        $currentLoopGroupArray = $storedGroups_de[$currentLoopGroupRef];
      else if ($lang === "en")
        $currentLoopGroupArray = $storedGroups_en[$currentLoopGroupRef];
      $currentLoopAddress = $currentHotspot.label;
      $currentLoopURL =
        $audioPath +
        $currentHotspot.language +
        $currentHotspot.file +
        $loopFileType;

      $krpano.call("tween( hotspot[loop-preview].alpha, 0.0, 0.0 );");
      $krpano.call(`looktohs(${$currentHotspot.name}, ${$krpano.view.fov} );`);

      window.location.href = "#/" + $currentLoop.slice(0, 3);

      // if (option !== "noScroll") scrollToItem($currentLoop)
      if (option !== "noScroll")
        setTimeout(() => scrollToItem($currentLoop), 300);
    } else {
      $loopPlayerOpen = false;
      $currentLoopIsPlaying = false;

      $currentLoop = "";
      $currentHotspot = "";
      $currentLoopGroupID = null;
      $currentLoopGroupRef = null;
      $currentLoopGroupArray = null;
      $currentLoopAddress = null;
      $currentLoopURL = null;
      $krpano.call("tween(hotspot[player].alpha, 0.0, 0.0);");
    }
  }

  export function setPreview(status) {
    if (trace) console.log("|-- setPreview(", status, ")");
    if (hs_preview == undefined) {
      hs_preview = getHotspot("loop-preview");
    }
    switch (status) {
      case "hide":
        hs_preview.alpha = 0;
        break;
      case "show":
        hs_preview.alpha = 1;
        break;
      case "disable":
        hs_preview.enabled = false;
        break;
    }
  }

  export function setLoopFocus(loop) {
    if (hs_preview == undefined) {
      hs_preview = getHotspot("loop-preview");
    }

    if (loop) {
      // If Loop is a GroupID
      if (typeof loop !== "object") {
        loop = getHotspot("#" + $lang + loop);
        if (trace) console.log("|––––––––– isObject: false", loop.id_new);
      }
      $focusedLoop = loop.id_new;
      $focusedHotspot = getHotspot(loop.name);
      if ($focusedHotspot) {
        if (trace)
          console.log(
            "|-- setLoopFocus()",
            loop.id_new,
            loop.name,
            $focusedHotspot.label
          );
        loop.url = "./assets/krpano/images/oval-white@2x.png";
        hs_preview.ath = $focusedHotspot.ath;
        hs_preview.atv = $focusedHotspot.atv;
        hs_preview.alpha = 0;
        $krpano.call("tween( hotspot[loop-preview].alpha, 0.0, 0.0 );");

        preview_timeout = setTimeout(function () {
          if ($focusedLoop === $currentLoop) {
            $krpano.call("tween( hotspot[loop-preview].alpha, 0.0, 0.0 );");
          } else {
            $krpano.call("tween( hotspot[loop-preview].alpha, 1.0, 0.3 );");
          }
          loop.url = "./assets/krpano/images/oval@2x.png";
        }, 100);
      }
    } else {
      if (trace) console.log("|-- setLoopFocus( undefined )");
      if (preview_timeout) clearTimeout(preview_timeout);
      if ($focusedHotspot)
        $focusedHotspot.url = "./assets/krpano/images/oval@2x.png";
      $focusedHotspot = "";
      $focusedLoop = "";
      $krpano.call("tween( hotspot[loop-preview].alpha, 0.0, 0.3 );");
    }
  }

  /* ––––––––––––––––––––––––––––––––––––––––––––––– */
  /* ––––––––––––––––– LOOP GROUPS ––––––––––––––––– */
  /* ––––––––––––––––––––––––––––––––––––––––––––––– */

  // export function getCurrentLoopGroupIDURL(groupID) {
  //   let groupName = '#'+$lang+groupID;
  //   if (trace) console.log("|--- getCurrentLoopGroupIDURL: ", groupName, groupID  )
  //   $krpano.call( `looktohs(${groupName}, ${app.fovLevel[4]} );` )
  //   $currentLoopIsPlaying = false
  //   $loopPlayerOpen = true
  //   setLoopActive(groupName)
  // }
  export function getCurrentLoopGroupIDFile(groupID) {
    if (trace)
      console.log(
        "|--- getCurrentLoopGroupIDFile: ",
        groupID,
        groupID.slice(0, 3)
      );
    return groupID.slice(0, 3);
  }
  export function getCurrentLoopGroupIDAddress(groupID) {
    // let filteredLoop = groupID.includes(loop.file+"-"+loop.pin.id))[0]
    // if (filteredLoop){
    //   // let place = $storedLoops.filter(loop => groupID.includes(loop.file+"-"+loop.pin.id))[0].place
    // } else {
    // }
    try {
      let place = $storedLoops.filter((loop) =>
        groupID.includes(loop.file + "-" + loop.pin.id)
      )[0].place;
      if (trace)
        console.log("|--- getCurrentLoopGroupIDAddress: ", groupID, place);
      return place;
    } catch (error) {
      if (trace)
        console.log(
          "|--- getCurrentLoopGroupIDAddress: 'no Place found!'",
          error
        );
    }
  }
  export function getCurrentLoopGroupIDDuration(groupID) {
    try {
      let duration = $storedLoops.filter((loop) =>
        groupID.includes(loop.file + "-" + loop.pin.id)
      )[0].duration;
      if (trace)
        console.log("|--- getCurrentLoopGroupIDDuration: ", groupID, duration);
      return duration;
    } catch (error) {
      if (trace)
        console.log(
          "|--- getCurrentLoopGroupIDDuration: 'no Duration found!'",
          error
        );
    }
  }

  export function scrollToItem(id) {
    let elemID = "ml" + id;
    if (trace) console.log("|–– scrollToItem() id:", elemID);
    var anchor = document.getElementById(elemID);
    if (anchor) {
      var parent = anchor.parentElement;
      var topPos = anchor.offsetTop;
      if (trace)
        console.log(
          "|–– scrollToItem() clicked:",
          id,
          topPos,
          getHotspot("#" + $lang + id).label
        );
      animateScroll.scrollTo({
        element: anchor,
        container: parent,
        easing: "circInOut",
        duration: 1000,
      });
      // parent.scroll({
      //   top: topPos-100,
      //   behavior: 'smooth'
      // })
    }
  }

  function createRenderLoop(caller) {
    // let lp = document.getElementById("sprite")
    // console.log("|---- createHotspots() delay:", delay, lp)
    // --------- IMPORTANT --------------------
    // $krpano.actions.renderloop(function(){
    //   if($Player){
    //     let p = $krpano.spheretoscreen( $currentHotspot.ath, $currentHotspot.atv );
    //     // lp.style.left = "300px"
    //     // lp.style.left = p.x+"px"
    //     // lp.style.top = p.y+"px"
    //     lp.style.transform = `translate3d(${p.x}px, ${p.y}px, 0px)`
    //     // lp.style.transform = `translate(${p.x}px, ${p.y}px)`
    //     lp.style.backgroundColor = "red"
    //     // console.log("|---- renderloop()")
    //   } else {
    //     lp.style.backgroundColor = "blue"
    //     // krpano.actions.stoprenderloop();
    //   }
    // });
  }

  /* ––––––––––––––––––––––––––––––––––––––––––––––– */
  /* ––––––––––––––––––– HOTSPOTS –––––––––––––––––– */
  /* ––––––––––––––––––––––––––––––––––––––––––––––– */

  export function createHotspots(delay) {
    for (let [i, loop] of $storedLoops.entries()) {
      setTimeout(() => {
        // if (trace) console.log("|---- createHotspots() index:", loop.nr)
        // if (trace) console.log("|---- createHotspots() index:", i, loop.name)
        // IMPORTANT: HotSpot names have to start with alphabetical character!
        var name = loop.name;
        $krpano.call("addhotspot(" + loop.name + ")");
        $krpano.set("hotspot[" + loop.name + "].id_new", loop.id_new);
        $krpano.set(
          "hotspot[" + loop.name + "].url",
          "./assets/images/oval.png"
        );
        $krpano.set("hotspot[" + loop.name + "].file", loop.file);
        $krpano.set("hotspot[" + loop.name + "].filename", loop.filename);
        $krpano.set("hotspot[" + loop.name + "].name", loop.name);
        $krpano.set("hotspot[" + loop.name + "].label", loop.place);
        $krpano.set("hotspot[" + loop.name + "].language", loop.lang);
        $krpano.set("hotspot[" + loop.name + "].ath", loop.pin.ath);
        $krpano.set("hotspot[" + loop.name + "].atv", loop.pin.atv);
        $krpano.set("hotspot[" + loop.name + "].lat", loop.pin.lat);
        $krpano.set("hotspot[" + loop.name + "].lon", loop.pin.long);
        $krpano.set("hotspot[" + loop.name + "].group_ref", loop.pin.group_ref);
        $krpano.set("hotspot[" + loop.name + "].group_id", loop.pin.group_id);
        $krpano.set("hotspot[" + loop.name + "].pin_id", loop.pin.id);
        $krpano.call("hotspot[" + loop.name + "].loadstyle('" + "loop" + "');");
      }, delay);
    }
  }

  export function getHotspot(name) {
    if (trace) console.log("|----- getHotspot(", name, ")");
    return $krpano.hotspot.getItem(name);
  }

  export function listHotspots() {
    if (trace) console.log("|----- listHotspots()");
    let hotspots = $krpano.hotspot.getArray();
    for (let hs of hotspots) {
      if (trace) console.log("hs:", hs.name);
    }
  }
  export function deleteHotspots() {
    if (trace) console.log("|----- deleteHotspots2()");
    let hotspots = $krpano.hotspot.getArray();
    for (let i in [1, 2, 3, 4, 5, 6, 7, 8, 9]) {
      for (let hs of hotspots) {
        if (hs.name.slice(0, 1) === "#") {
          $krpano.removehotspot(hs.name);
        }
      }
    }
    listHotspots();
  }

  export function openDownloadWindow(arg) {
    let _loop = $currentLoop.slice(0, 3);
    let filename = $storedLoops.filter((loop) => loop.file.includes(_loop))[0]
      .filename;
    let url = `${$downloadPath}${filename}`;
    // if (trace) console.log("| >>> $focusedLoop.filename", filename, url);
    if (trace)
      console.log(
        "|-- focusedLoop:",
        $focusedLoop,
        "currentLoop",
        $currentLoop
      );

    //https://stackoverflow.com/questions/3975648/how-to-set-content-disposition-attachment-via-javascript
    fetch(url, {
      method: "GET",
    })
      .then(function (resp) {
        return resp.blob();
      })
      .then(function (blob) {
        const newBlob = new Blob([blob], {
          type: "audio/mpeg",
          charset: "UTF-8",
        });
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const data = window.URL.createObjectURL(newBlob);
        if (trace) console.log("data:", data);
        const link = document.createElement("a");
        link.dataType = "mp3";
        link.href = data;
        link.download = filename;
        link.dispatchEvent(new MouseEvent("click"));
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data), 60;
        });
      });
  }

  //---------- Service Worker ------------------------------

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/service-worker.js");
  }

  // -------------------------------------------------------

  onMount(() => {
    if (isDevelopment) {
      // setInterval(()=>{
      // currentfps = $krpano.display.currentfps.toFixed(2)
      // progress = $krpano.progress.progress
      // currFov = $krpano.view.fov.toFixed(3)
      // }, 100)
    }
    // switch initially to /#home route
    // parent.location.hash = "";
    push("/");

    sortBy(["place"]);

    // sortBy(['place'])
    // sortBy([ 'file', 'pin.id' ])

    // sortBy([ 'file', 'pin.id' ])
    // sortBy(['place'])

    // sortBy([ 'file', 'pin.id' ])
    // sortBy(['place'])
    // sortBy(['place'])
    // sortBy([  'pin.id', 'file' ])
    // sortBy(['place'])
  });

  // ---------- MENU ---------------------------------------

  const menuItems = [];
  menuItems["de"] = [
    { title: "Start", route: "/home", state: "EXPLORE.MAP" },
    { title: "Über", route: "/ueber", state: "EXPLORE.INFO" },
    { title: "Hörspiel", route: "/hoerspiel", state: "EXPLORE.INFO" },
    { title: "Hilfe", route: "/hilfe", state: "EXPLORE.INFO" },
    { title: "Impressum", route: "/impressum", state: "EXPLORE.INFO" },
    { title: "Datenschutz", route: "/datenschutz", state: "EXPLORE.INFO" },
  ];
  menuItems["en"] = [
    { title: "Home", route: "/home", state: "EXPLORE.MAP" },
    { title: "About", route: "/about", state: "EXPLORE.INFO" },
    { title: "Radio-Play", route: "/radioplay", state: "EXPLORE.INFO" },
    { title: "Help", route: "/help", state: "EXPLORE.INFO" },
    { title: "Imprint", route: "/imprint", state: "EXPLORE.INFO" },
    { title: "Privacy", route: "/privacy", state: "EXPLORE.INFO" },
  ];

  // ----------- EVENT HANDLING ------------------------------

  function isMobileCheck() {
    showMobileMenu = false;
    isMobile = window.matchMedia("only screen and (max-width: 900px)").matches;
    isPhone = window.matchMedia("only screen and (max-width: 420px)").matches;
    // isMobile = window.matchMedia("only screen and (max-width: 760px)").matches
    if (trace) console.log("|----- isMobile(): ", isMobile);
    return isMobile;
  }

  document.addEventListener(
    "keydown",
    function (e) {
      if (e.keyCode == 13) {
        toggleFullScreen();
      }
    },
    false
  );
  window.addEventListener("popstate", function (event) {
    // The URL changed...
    let locationHref = window.location.href.split("/");
    let param = locationHref[locationHref.length - 1];
    if (parseFloat(param)) {
      let id = param + "-1";
      // if (trace) console.log("|-------------------- state3: ", id, param);
      setLoopFocus(id);
      setLoopActive(id);
    }
  });

  function toggleFullScreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  function testt() {
    if (trace) console.log("jetzt");
  }
  function testtt() {
    if (trace) console.log("jetzt 2");
  }
</script>

<!-- <svelte:window on:keydown={setIdle}/> -->
<!-- <svelte:window on:keydown="{()=>states.set('TOGGLE')}"/> -->
<!-- <svelte:body on:drag="{()=>states.set('EXPLORE')}"/> -->

<!-- <svelte:body on:wheel|passive={()=>handleOnWheelEvents(event)}/> -->
<svelte:window bind:online={isOnline} on:resize={isMobileCheck} />
<svelte:head>
  <title>{"Memory Loops"}</title>
</svelte:head>
<!-- <svelte:window bind:online={isOnline} on:online={()=>states.set('ONLINE')} on:offline={()=>states.set('OFFLINE')}/> -->
<!-- <svelte:window bind:online={isOnline} on:online={handleNetworkState} on:offline={handleNetworkState}/> -->

<!-- ---------------------------------------------------------------------- -->
<!-- ---------------------------------------------------------------------- -->

<main>
  <!-- <div id="preview" class="preview" on:click='{()=>scrollToItem($focusedHotspot.id_new)}'> -->
  <!-- {#if $focusedLoop} -->
  <div id="preview" class="preview">
    <div class="flex">
      <img
        src="./assets/images/oval-active-vector-fill.svg"
        class=""
        style="height:32px;"
        alt=""
      />
      <div class="flex-grow-0 title-id  text-gray777 ml-2">
        {$focusedHotspot.file}
      </div>
      <div class="flex-grow title-place text-white ml-1">
        {$focusedHotspot.label}
      </div>
    </div>
  </div>
  <!-- {/if} -->

  <div id="plyr">
    {#if $loopPlayerOpen}
      <div
        class="player select-none"
        on:mouseenter|self|stopPropagation={() => testtt()}
        on:mouseover|self|stopPropagation={() => testt()}
        class:bg-big={$currentLoopGroupRef}
      >
        <div class="flex">
          <LoopIcon
            currentID={$currentLoop}
            type="player"
            class="loopIcon flex-1 self-center ml-8"
          />
          <div class="flex-grow-0 title-id text-gray777 ml-2">
            {$currentHotspot.file}
          </div>
          <div class="flex-grow title-place text-white ml-1">
            {$currentHotspot.label}
          </div>
          <button
            on:click={() => closePlayer()}
            class="rounded-sm py-1 px-1 mr-2 hover:text-gray999"
          >
            <svg
              height="16"
              viewBox="0 0 12 12"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
              ><g
                style="stroke:#979797;stroke-width:1.7;fill:none;fill-rule:evenodd;stroke-linecap:round;stroke-linejoin:bevel"
                transform="translate(3 3)"
                ><path d="m0 0 6 6" transform="matrix(-1 0 0 1 6 0)" /><path
                  d="m0 0 6 6"
                /></g
              ></svg
            >
          </button>
        </div>
        <div id="LoopPlayer" class="flex">
          <LoopPlayer src={$currentLoopURL} title="Text" />
        </div>
      </div>
    {/if}
  </div>
  <!-- <div class="flex-grow-0 title-id text-gray777 ml-2">{$currentLoop.slice(0,3)}</div>
          <div class="flex-grow title-place bold text-white ml-1">{$currentLoopAddress}</div> -->

  <!-- ---------------------------------------------------------------------- -->

  {#if isDevelopment}
    <div class="debug absolute top-0 p-2 z-50 w-auto text-black text-xs">
      <div
        id="state"
        class="{isOnline ? 'bg-green-500' : 'bg-red-500'} m-1 p-1 w-auto"
      >
        {states.current()}
        <!-- wird dynamisch ersetzt durch stateStore -->
      </div>
      <div
        id="mob"
        class="{isMobile ? 'bg-green-500' : 'bg-red-500'} m-1 p-1 w-auto"
      >
        <!-- FPS: {currentfps} -->
        <!-- <br>Progress: {progress} -->
        <!-- <br>currFov: {currFov} -->
        <!-- <br>location: {$location} -->
        <!-- <br>isMobile: {isMobile} -->
        <!-- <br><button class="bg-red-200 hover:bg-red-600 px-1" on:click={()=>$krpano.call("deleteAllHotspots();")}>+</button> Delete All HotSpots -->
        <!-- <br><button class="bg-red-200 hover:bg-red-600 px-1" on:click={()=>toggleLoopPlayer()}>toggle</button> $loopPlayerOpen: {$loopPlayerOpen} -->
        <br /><button
          class="bg-blue-200 hover:bg-blue-600 px-1"
          on:click={toggleCurrentLoopIsPlaying}>toggle</button
        >
        $currentLoopIsPlaying: {$currentLoopIsPlaying}
        <br />$currentLoopIsPlaying: {$currentLoopIsPlaying}
        <br /><button
          class="bg-blue-200 hover:bg-blue-600 px-1"
          on:click={toggleLoopPlayer}>toggle</button
        >
        $loopPlayerOpen: {$loopPlayerOpen}
        <br />$loopPlayerOpen: {$loopPlayerOpen}
        <!-- <br>currentLoopAddress: {$currentLoopAddress} -->
        <!-- <br>$currentLoopGroupRef: {$currentLoopGroupRef} -->
        <!-- <br>$currentLoopGroupID: {$currentLoopGroupID} -->
        <!-- <br>$currentLoopGroupArray: {$currentLoopGroupArray} -->
        <!-- <br>$currentLoopURL: {$currentLoopURL} -->
        <br />
        <br />$focusedLoop: {$focusedLoop}
        <br />$currentLoop: {$currentLoop}
        <!-- <br> -->
        <br /><b>$focusedHotspot:</b>
        {$focusedHotspot.name}
        <br /><b>$currentHotspot:</b>
        {$currentHotspot.name}
        <br />
        <!-- <br> $currentHotspot.ath: {$currentHotspot.ath} -->
        <!-- <br> $currentHotspot.atv: {$currentHotspot.atv} -->
      </div>
    </div>
    {#if showDebugPanel}
      <!-- <DebugPanel/> -->
    {/if}
  {/if}

  <!-- ---------------------------------------------------------------------- -->

  {#if showRoutes}
    <Router {routes} />
  {/if}

  <div id="lst" class="">
    {#if $loopListOpen}
      <LoopListPanel />
    {/if}
  </div>

  <!-- 
    {#if $loopPlayerOpen}
    {/if} 
  -->

  {#if showMap}
    <Krpano ref="krpano.xml" />
  {/if}

  <!-- –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– -->
  <!-- –––––––– HEADER –––––––––––––––––––––––––––––––––––––––––––––––––––––––––– -->
  <!-- –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– -->

  <!-- HEADER -->
  {#if showHeader}
    <header
      class="fixed z-40 w-48 sm:w-24 lg:w-56 "
      in:fly={animIn}
      out:fly={animOut}
    >
      <a
        href="/home"
        on:click={() => states.set("TOGGLE")}
        use:link
        class="p-0"
      >
        <img
          class="hidden sm:block"
          src="./assets/images/logo2.svg"
          alt="Memory Loops"
          in:fly={animIn}
          out:fly={animOut}
        />
        <img
          class="block sm:hidden"
          src="./assets/images/logo2-left.svg"
          alt="Memory Loops"
          in:fly={animIn}
          out:fly={animOut}
        />
      </a>
    </header>
  {/if}

  <!-- –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– -->
  <!-- –––––––– FOOTER –––––––––––––––––––––––––––––––––––––––––––––––––––––––––– -->
  <!-- –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– -->

  {#if showNavi}
    <footer class="fixed bottom-0 w-full white" style="z-index:42;">
      <!-- FOOTER GRIMME -->
      {#if showGrimme}
        <div
          id="grimme"
          class="fixed bottom-0 right-0 mx-5 my-16 z-70"
          in:fly={animIn}
          out:fly={animOut}
        >
          <form
            action="https://www.grimme-online-award.de/archiv/2012/preistraeger/p/d/memory-loops/"
            method="get"
            target="_blank"
            style="height: 92px;"
          >
            <button type="submit"
              ><img
                src="./assets/images/grimme-logo.svg"
                alt="Grimme Award"
              /></button
            >
          </form>
        </div>
      {/if}

      <!-- FOOTER PARTNER -->
      {#if showPartner}
        <div id="partner" class="fixed bottom-0 left-0 mx-4 my-3 z-80">
          <div class="flex items-center">
            <div
              class="w-24 mr-4"
              class:hide={isPhone}
              style="margin-top:15px; max-width:20vw;"
            >
              <img
                src="./assets/images/munich-wb.svg"
                alt="Munich"
                in:fly={animIn}
                out:fly={animOut}
              />
            </div>
            <div
              class="w-24 hidden sm:block opacity-75"
              style="margin-top:21px; max-width:20vw; width:85px !important;"
            >
              <img
                src="./assets/images/br2.svg"
                alt="BR"
                in:fly={animIn}
                out:fly={animOut}
              />
            </div>
          </div>
        </div>
      {/if}

      <!-- FOOTER CLAIM -->
      <div
        id="claim"
        class="fixed bottom-0 right-0 mx-5 z-50"
        style="margin-bottom:13px;"
        in:fly={animIn}
        out:fly={animOut}
      >
        <img src="./assets/images/claim_thin_{$lang}.svg" alt="Claim" />
      </div>

      <!-- ––––––––––––––––––––––––––––––––––––– -->
      <!-- MOBILE MENU / EXPLORE STATE -->
      <!-- ––––––––––––––––––––––––––––––––––––– -->

      {#if isMobile}
        <!-- NAV.HAMBURGER -->
        <nav
          class="fixed bottom-0 left-0 mx-4 my-3 z-90"
          in:fade={{ duration: 300 }}
        >
          {#if !showPartner}
            <button
              on:click={toggleMobileMenu}
              class="z-20 block text-gray999 hover:text-white focus:text-white focus:outline-none"
            >
              <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
                {#if showMobileMenu}
                  <path
                    fill-rule="evenodd"
                    d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                  />
                {:else}
                  <path
                    fill-rule="evenodd"
                    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                  />
                {/if}
              </svg>
            </button>
          {/if}
        </nav>

        <!-- NAV.MENU.BAR --- Explore State / Tool-Buttons & Menuitems-->
        <nav
          class="{showMobileMenu ? 'block' : 'hidden'} mobmen px-4 pt-4 pb-0"
        >
          {#if $lang === "de"}
            {#each menuItems["de"] as item}
              <a
                href={item.route}
                on:click={() => states.set(item.state)}
                on:click={toggleMobileMenu}
                class="nav-link"
                use:link
                use:active={item.route}>{item.title}</a
              >
            {/each}
          {:else if $lang === "en"}
            {#each menuItems["en"] as item}
              <a
                href={item.route}
                on:click={() => states.set(item.state)}
                on:click={toggleMobileMenu}
                class="nav-link"
                use:link
                use:active={item.route}>{item.title}</a
              >
            {/each}
          {/if}
          <div class="flex mt-4">
            <button on:click={toggleLanguage} class="nav-link-btn" alt="Lang"
              >{$lang.toUpperCase()}</button
            >
            <button on:click={toggleFullScreen} class="nav-link-btn">
              <img
                class="opacity-50"
                style="width:16px;"
                src="./assets/images/fullscreen.svg"
                alt="Fullscreen"
              />
            </button>
            <button on:click={toggleLoopList} class="nav-link-btn">
              <img
                class="opacity-50"
                src="./assets/images/icon-list.svg"
                alt="List"
              />
            </button>
          </div>
        </nav>
      {/if}

      {#if !isMobile}
        {#if showMenuBar}
          <!-- NAV.MENU.BAR -->
          <nav class="fixed bottom-0 left-0 px-3 w-full z-50">
            <div
              class="{showMobileMenu ? 'block' : 'hidden'} sm:flex"
              style="margin-bottom: 5px;"
            >
              <button
                on:click={toggleLanguage}
                class="nav-util sm:w-auto mr-1"
                style="margin-left:7px !important;"
              >
                {$lang.toUpperCase()}
              </button>
              <button
                on:click={toggleFullScreen}
                class="nav-util nav-link-fullscreen w-3 sm:w-auto mr-1"
              >
                <img
                  class="opacity-60"
                  style="width:16px;"
                  src="./assets/images/fullscreen.svg"
                  alt="Fullscreen"
                />
              </button>
              <button
                on:click={toggleLoopList}
                class="nav-util sm:w-auto"
                style="margin-right: 15px !important;"
              >
                <img
                  class="opacity-50"
                  style="width:18px;"
                  src="./assets/images/icon-list.svg"
                  alt="List"
                />
              </button>

              {#if $lang === "de"}
                {#each menuItems["de"] as item, i}
                  {#if i > 0}<div class="nav-div pl-0 pr-0">|</div>{/if}
                  <a
                    href={item.route}
                    on:click={() => states.set(item.state)}
                    class="nav-link"
                    use:link
                    use:active={item.route}>{item.title}</a
                  >
                {/each}
              {:else if $lang === "en"}
                {#each menuItems["en"] as item, i}
                  {#if i > 0}<div class="nav-div pl-0 pr-0">|</div>{/if}
                  <a
                    href={item.route}
                    on:click={() => states.set(item.state)}
                    class="nav-link"
                    use:link
                    use:active={item.route}>{item.title}</a
                  >
                {/each}
              {/if}
            </div>
          </nav>
        {/if}
      {/if}

      <!-- –––––––––––––––––––––––––––––––––––––––––––––––– -->
      <!-- FOOTER Background –– MOBILE / DESKTOP  -->
      <!-- –––––––––––––––––––––––––––––––––––––––––––––––– -->

      <img
        class="fixed w-full bottom-0 left-0"
        class:lift-sm={isPhone}
        class:lift-md={isMobile && isPhone == false}
        style="z-index:0; height:75px; opacity: 0.95;"
        src="./assets/images/footer-bg-3.png"
        alt="Footer"
      />

      <!-- –––––––––––––––––––––––––––––––––––––––––––––––– -->
      <!-- LOADER –– MOBILE / DESKTOP – EXPLORE & IDLE STATE -->
      <!-- –––––––––––––––––––––––––––––––––––––––––––––––– -->

      {#if showLoader}
        <div class="" in:fly={animIn} out:fly={animOut}>
          <!-- <LoadingSpinner /> -->
        </div>
      {/if}
    </footer>
  {/if}
</main>

<!-- ---------------------------------------------------------------------- -->
<!-- ---------------------------------------------------------------------- -->

<!-- ---------------------------------------------------------------------- -->
<style>
  .lift-md {
    height: 85px !important;
  }
  .lift-sm {
    height: 96px !important;
  }

  .hide {
    display: none;
  }
  .mobmen {
    background: #222 !important;
    opacity: 0.95 !important;
    margin-bottom: 35px !important;
    padding-top: 0.8rem !important;
    padding-left: 0.8rem !important;
    padding-right: 0.8rem !important;
    z-index: -1 !important;
    position: relative !important;
    height: 300px !important;
  }

  .init {
    z-index: 500;
  }
  .show {
    opacity: 1 !important;
    transition: all 0.3s ease !important;
  }
  .hide {
    opacity: 0 !important;
    transition: all 0.3s ease !important;
  }

  .info {
    position: absolute;
    /* background-color: #111; */
    /* z-index: 3003 !important; */
    z-index: 40;
    width: 100% !important;
    height: 100vh;
  }
  /* 
  footer {
    z-index: 2004;
  }
   */
  header {
    z-index: 3004;
  }
  .debug {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1rem !important;
    letter-spacing: 0.1px !important;
    position: absolute;
    z-index: 10010;
    left: 100px;
  }

  /* --------------- from loopPlayerPanel ---------------- */
  .bg-mini {
    height: 32px !important;
    background: url("./assets/images/loop-player-bg-mini.svg") no-repeat !important;
  }
  .bg-small {
    /* height: 110px !important; */
    height: 80px !important;
    background: url("./assets/images/loop-player-bg-smaller.svg") no-repeat !important;
  }
  .bg-big {
    height: 200px !important;
    background: url("./assets/images/loop-player-bg-big.svg") no-repeat !important;
  }
  .lst {
    z-index: 10;
    background: url("./assets/images/loop-player-bg-smaller.svg") no-repeat;
    background-position: -9px -6px !important;
    position: absolute;
    width: 225px;
    height: 110px;
    left: 0px;
    top: 0px;
    border-radius: 4px;
    margin: 0px 0px;
    opacity: 0.85;
  }
  .player {
    z-index: 10;
    background: url("./assets/images/loop-player-bg-smaller.svg") no-repeat;
    background-position: -9px -6px !important;
    position: absolute;
    width: 225px;
    height: 110px;
    left: 0px;
    top: 0px;
    border-radius: 4px;
    margin: 0px 0px;
    opacity: 0.85;
  }
  .preview {
    z-index: 10;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: black;
    border: 2px solid #333 !important;
    border-radius: 18px;
    padding-right: 15px;
    position: absolute;
    width: auto;
    height: 36px;
    left: -300px;
    top: -300px;
    margin: 0px 0px;
    opacity: 0.85;
  }

  .title {
    text-overflow: ellipsis;
    letter-spacing: 0.7px;
    font-size: 0.85rem;
    margin-top: 2px;
    min-width: 24px !important;
    @apply self-center text-xs overflow-hidden whitespace-no-wrap tracking-wider;
  }
  .title-id {
    font-family: "GraphikMedium";
    text-overflow: ellipsis;
    letter-spacing: 0.7px;
    font-size: 0.85rem;
    margin-top: 2px;
    min-width: 24px !important;
    @apply self-center text-xs overflow-hidden whitespace-no-wrap tracking-wider;
  }
  .title-place {
    font-family: "GraphikBold";
    text-overflow: ellipsis;
    letter-spacing: 0.7px;
    font-size: 0.85rem;
    margin-top: 2px;
    min-width: 24px !important;
    max-width: 121px !important;
    @apply self-center text-xs overflow-hidden whitespace-no-wrap tracking-wider;
  }
  #LoopPlayer {
    margin-left: 2.4rem;
  }

  #loopIcon {
    width: 32px;
    height: 32px;
    /* width: 32px;
      height: 32px; */
    background-position: 50%;
    background-size: 75% !important;
    align-self: center;
    /* margin: 5px 7px !important; */
  }

  /*  
  */
</style>
